/*@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');*/
/*@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');*/

@import './style/typo.css';
@import './style/index.antd.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
	font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
		Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

/*Moved to tailwind.config*/
/*.oxanium {*/
/*  font-family: 'Oxanium', cursive !important;*/
/*}*/

/*html, body {*/
/*  background: #101214;*/
/*}*/

/*Нельзы выделять текст*/
.noselect {
	-webkit-touch-callout: none;
	/* iOS Safari */
	-webkit-user-select: none;
	/* Safari */
	-khtml-user-select: none;
	/* Konqueror HTML */
	-moz-user-select: none;
	/* Old versions of Firefox */
	-ms-user-select: none;
	/* Internet Explorer/Edge */
	user-select: none;
	/* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

/*При автозаполнеии не менять фон*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	background: #4b5563;
}

/*scrollbar bg*/
.treeScroller::-webkit-scrollbar-track {
	background: transparent;
	/* color of the tracking area */
}

.treeScroller::-webkit-scrollbar-thumb {
	background-color: transparent;
	/* color of the scroll thumb */
	border-radius: 20px;
	/* roundness of the scroll thumb */
	border: 3px solid transparent;
	/* creates padding around scroll thumb */
}

.treeScroller {
	scrollbar-color: #757575 transparent;
}

body {
	overflow-x: hidden;
	overflow-y: hidden;
}

.calendar {
	background-color: #f8fafc !important;
}

.border {
	border-width: 0px !important;
}

.float-right {
	display: inline-flex !important;
	justify-content: flex-end;
	/* margin-left: 300px !important; */
}

.color-fg-muted {
	display: inline-flex !important;
	justify-content: flex-end;
}

.container {
	padding: 0rem !important;
	width: 100% !important;
	max-width: 100% !important;
}

ul.ulInLine {
	margin: 0; /* Обнуляем значение отступов */
	padding: 4px; /* Значение полей */
}
ul.ulInLine li {
	display: inline; /* Отображать как строчный элемент */
	margin-right: 5px; /* Отступ слева */
	border: 1px solid #000; /* Рамка вокруг текста */
	padding: 3px; /* Поля вокруг текста */
}

.ant-select-selector {
	height: 100% !important;
}


.highlight-line {
  background-color: rgba(255, 255, 0, 0.3); /* Лёгкая жёлтая подсветка */
}